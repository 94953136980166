import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './base';
import toast from 'react-hot-toast';
import { handleApiError } from '@src/utility/errorHandler';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '@src/utility/api';

export const applicationsApi = createApi({
    reducerPath: 'applicationsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Application', 'ApplicationSecrets'],
    endpoints: (builder) => ({
        getApplications: builder.query({
            query: (filters) => ({
                url: '/api/app-registrations/search',
                method: 'POST',
                data: filters,
            }),
            transformResponse: (response) => {
                const { data, ...meta } = response;
                return { items: data, meta };
            },
            // Enable automatic re-fetching when mutations happen
            providesTags: ['Application'],
            // Handle errors
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch applications');
                }
            }
        }),

        addApplication: builder.mutation({
            query: (applicationData) => ({
                url: '/api/app-registrations',
                method: 'POST',
                data: applicationData,
            }),
            transformResponse: (response) => response,
            // Invalidate the Permission tag to trigger a refetch
            invalidatesTags: ['Application'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Application added successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to add application');
                }
            }
        }),

        deleteApplication: builder.mutation({
            query: (applicationId) => ({
                url: `/api/app-registrations/${applicationId}`,
                method: 'DELETE',
            }),
            // Invalidate the Application tag to trigger a refetch
            invalidatesTags: ['Application'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Application deleted successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to delete application');
                }
            }
        }),

        createApplicationSecret: builder.mutation({
            query: ({ appId, secretData }) => ({
                url: `/api/app-registrations/${appId}/secrets`,
                method: 'POST',
                data: secretData
            }),
            invalidatesTags: (result, error, { appId }) => [{ type: 'ApplicationSecrets', id: appId }],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Secret created successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to create secret');
                }
            }
        }),

        getApplicationSecrets: builder.query({
            query: (appId) => ({
                url: `/api/app-registrations/${appId}/secrets`,
                method: 'GET'
            }),
            providesTags: (result, error, appId) => [{ type: 'ApplicationSecrets', id: appId }]
        }),

        revokeApplicationSecret: builder.mutation({
            query: ({ appId, secretId }) => ({
                url: `/api/app-registrations/${appId}/secrets/${secretId}/revoke`,
                method: 'GET'
            }),
            invalidatesTags: (result, error, { appId }) => [{ type: 'ApplicationSecrets', id: appId }],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Secret revoked successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to revoke secret');
                }
            }
        }),

        updateApplication: builder.mutation({
            query: ({ id, ...applicationData }) => ({
                url: `/api/app-registrations/${id}`,
                method: 'PUT',
                data: applicationData
            }),
            invalidatesTags: ['Application'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Application updated successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to update application');
                }
            }
        }),
    }),
});

export const fetchApplicationPermissions = createAsyncThunk(
  'applications/fetchApplicationPermissions',
  async (applicationId) => {
    try {
      const response = await api.get(`/api/app-registrations/${applicationId}/permissions`);      
      return response.data.permissions;
    } catch (error) {
      const errors = handleApiError(error.error, 'Failed to fetch application permissions');
      error.validationErrors = errors;
      throw error;
    }
  }
);

export const assignApplicationPermissions = createAsyncThunk(
  'applications/assignPermissions',
  async ({ applicationId, newPermissions, permissionsToRemove }) => {
    try {
      const response = await api.post(`/api/app-registrations/${applicationId}/permissions`, {
        newPermissions,
        permissionsToRemove
      });
      return response.data;
    } catch (error) {
      const errors = handleApiError(error.error, 'Failed to assign permissions');
      error.validationErrors = errors;
      throw error;
    }
  }
);

const applicationsSlice = createSlice({
  name: 'applications',
  initialState: {
    submitting: false,
    error: null,
    initialSelectedPermissions: [],
  },
  reducers: {
    clearSelectedPermissions: (state) => {
      state.initialSelectedPermissions = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplicationPermissions.fulfilled, (state, action) => {        
        state.initialSelectedPermissions = action.payload;        
      })
      .addCase(assignApplicationPermissions.pending, (state) => {
        state.submitting = true;
      })
      .addCase(assignApplicationPermissions.fulfilled, (state) => {
        state.submitting = false;
      })
      .addCase(assignApplicationPermissions.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.error.message;
      });
  },
});

export const { clearSelectedPermissions } = applicationsSlice.actions;

export const reducer = applicationsSlice.reducer;

// Export hooks for usage in components
export const {
    useGetApplicationsQuery,
    useAddApplicationMutation,
    useDeleteApplicationMutation,
    useCreateApplicationSecretMutation,
    useGetApplicationSecretsQuery,
    useRevokeApplicationSecretMutation,
    useUpdateApplicationMutation,
} = applicationsApi;
