import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@src/utility/api';
import { handleApiError } from '@src/utility/errorHandler';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './base';


export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Users'],
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: (filters) => ({
                url: '/api/users/search',
                method: 'POST',
                data: filters,
            }),
            transformResponse: (response) => {
                const { data, ...meta } = response;
                return { items:data, meta };
            },
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch users');
                }
            },
            providesTags: ['Users']
        }),

    })
});



export const fetchUserPermissions = createAsyncThunk(
  'users/fetchUserPermissions',
  async (userId) => {
    try {
        const response = await api.get(`/api/users/${userId}/permissions`);
        return {
          userPermissions: response.data.userPermissions,
          rolesPermissions: response.data.rolesPermissions
        };
    } catch (error) {
        const errors = handleApiError(error.error, 'Failed to fetch user permissions');
        // Attach errors to the rejected promise for the reducer to access
        error.validationErrors = errors;
        throw error;
    }
  }
);

export const assignUserPermissions = createAsyncThunk(
    'users/assignPermissions',
    async ({ userId, newPermissions, permissionsToRemove }) => {
        try {
            const response = await api.post(`/api/users/${userId}/permissions`, {
                newPermissions,
                permissionsToRemove
            });
            return response.data;
        } catch (error) {
            const errors = handleApiError(error.error, 'Failed to assign permissions');
            error.validationErrors = errors;
            throw error;
        }
    }
);

export const fetchUserRoles = createAsyncThunk(
  'users/fetchUserRoles',
  async (userId) => {
    try {
        const response = await api.get(`/api/users/${userId}/roles`);
        return response.data;
    } catch (error) {
        const errors = handleApiError(error.error, 'Failed to fetch user roles');
        error.validationErrors = errors;
        throw error;
    }
  }
);

export const assignUserRoles = createAsyncThunk(
    'users/assignRoles',
    async ({ userId, newRoles, rolesToRemove }) => {
        try {
            const response = await api.post(`/api/users/${userId}/roles`, {
                newRoles,
                rolesToRemove
            });
            return response.data;
        } catch (error) {
            const errors = handleApiError(error.error, 'Failed to assign roles');
            error.validationErrors = errors;
            throw error;
        }
    }
);

const initialState = {
    items: [],
    loading: false,
    submitting: false,
    error: null,
    errors: null,
    selectedItem: {},
    openModal: false,
    selectedUserPermissions: [],
    selectedUserRolesPermissions: [],
    selectedUserRoles: []
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        clearSelectedUserPermissions: (state) => {
            state.selectedUserPermissions = [];
            state.selectedUserRolesPermissions = [];
        },
        clearSelectedUserRoles: (state) => {
            state.selectedUserRoles = [];
        },
        setSelectedUserRoles: (state, action) => {
            state.selectedUserRoles = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(assignUserPermissions.pending, (state) => {
                state.submitting = true;
            })
            .addCase(assignUserPermissions.fulfilled, (state) => {
                state.submitting = false;
                state.openModal = false;
                state.selectedUserPermissions = [];
                state.selectedUserRolesPermissions = [];
            })
            .addCase(assignUserPermissions.rejected, (state, action) => {
                state.submitting = false;
                state.error = action.error.message;
            })
            .addCase(fetchUserPermissions.fulfilled, (state, action) => {
                state.selectedUserPermissions = action.payload.userPermissions;
                state.selectedUserRolesPermissions = action.payload.rolesPermissions;
                state.loading = false;
            })
            .addCase(fetchUserRoles.fulfilled, (state, action) => {
                state.selectedUserRoles = action.payload.roles;
                state.loading = false;
            })
            .addCase(assignUserRoles.pending, (state) => {
                state.submitting = true;
            })
            .addCase(assignUserRoles.fulfilled, (state) => {
                state.submitting = false;
                state.selectedUserRoles = [];
            })
            .addCase(assignUserRoles.rejected, (state, action) => {
                state.submitting = false;
                state.error = action.error.message;
            })
    }
})

export const { 
    clearSelectedUserPermissions, 
    clearSelectedUserRoles, 
    setSelectedUserRoles 
} = usersSlice.actions


export const {
    useGetUsersQuery
} = usersApi; 

export default usersSlice.reducer
