// ** Reducers Imports
import layout from "./layout";
import navbar from "./navbar";
import users from './users'
import roles from './roles'
import { permissionsApi } from './permissions';
import { rolesApi } from './roles';
import { usersApi } from './users';
import { applicationsApi } from './applications';
import { combineReducers } from 'redux';
import { reducer as applications } from './applications';

const rootReducer = combineReducers({
  navbar,
  layout,
  users,
  roles,
  applications,
  [permissionsApi.reducerPath]: permissionsApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [applicationsApi.reducerPath]: applicationsApi.reducer,
});

export default rootReducer;
