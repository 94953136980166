import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './base';
import toast from 'react-hot-toast';
import { handleApiError } from '@src/utility/errorHandler';

export const permissionsApi = createApi({
    reducerPath: 'permissionsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Permission'],
    endpoints: (builder) => ({
        getPermissions: builder.query({
            query: (filters) => ({
                url: '/api/permissions/search',
                method: 'POST',
                data: filters,
            }),
            transformResponse: (response) => {
                const { data, ...meta } = response;
                return { items: data, meta };
            },
            // Enable automatic re-fetching when mutations happen
            providesTags: ['Permission'],
            // Handle errors
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch permissions');
                }
            }
        }),

        addPermission: builder.mutation({
            query: (permissionData) => ({
                url: '/api/permissions',
                method: 'POST',
                data: permissionData,
            }),
            transformResponse: (response) => response.data,
            // Invalidate the Permission tag to trigger a refetch
            invalidatesTags: ['Permission'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Permission added successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to add permission');
                }
            }
        }),

        deletePermission: builder.mutation({
            query: (permissionId) => ({
                url: `/api/permissions/${permissionId}`,
                method: 'DELETE',
            }),
            // Invalidate the Permission tag to trigger a refetch
            invalidatesTags: ['Permission'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Permission deleted successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to delete permission');
                }
            }
        }),

        getPermissionById: builder.query({
            query: (id) => ({
                url: `/api/permissions/${id}`,
                method: 'GET',
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['Permission'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch permission');
                }
            }
        }),

        updatePermission: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/api/permissions/${id}`,
                method: 'PUT',
                data,
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Permission'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Permission updated successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to update permission');
                }
            }
        }),
    }),
});

// Export hooks for usage in components
export const {
    useGetPermissionsQuery,
    useAddPermissionMutation,
    useDeletePermissionMutation,
    useGetPermissionByIdQuery,
    useUpdatePermissionMutation,
} = permissionsApi;
