import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '@src/utility/api';
import { handleApiError } from '@src/utility/errorHandler';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './base';
import toast from 'react-hot-toast';


export const rolesApi = createApi({
    reducerPath: 'rolesApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Roles'],
    endpoints: (builder) => ({
        getRoles: builder.query({
            query: (filters) => ({
                url: '/api/roles/search',
                method: 'POST',
                data: filters,
            }),
            transformResponse: (response) => {
                const { data, ...meta } = response;
                return { items: data, meta };
            },
            providesTags: ['Roles'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch roles');
                }
            }
        }),

        addRole: builder.mutation({
            query: (roleData) => ({
                url: '/api/roles',
                method: 'POST',
                data: roleData,
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Roles'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Role added successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to add role');
                }
            }
        }),

        deleteRole: builder.mutation({
            query: (roleId) => ({
                url: `/api/roles/${roleId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Roles'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Role deleted successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to delete role');
                }
            }
        }),
    }),
});

export const fetchRolePermissions = createAsyncThunk(
  'roles/fetchRolePermissions',
  async (roleId) => {
    try {
      const response = await api.get(`/api/roles/${roleId}/permissions`);      
      return response.data.permissions;
    } catch (error) {
      const errors = handleApiError(error.error, 'Failed to fetch role permissions');
      // Attach errors to the rejected promise for the reducer to access
      error.validationErrors = errors;
      throw error;
    }
  }
);

export const assignRolePermissions = createAsyncThunk(
  'roles/assignPermissions',
  async ({ roleId, newPermissions, permissionsToRemove }) => {
    try {
      const response = await api.post(`/api/roles/${roleId}/permissions`, {
        newPermissions,
        permissionsToRemove
      });
      return response.data;
    } catch (error) {
      const errors = handleApiError(error.error, 'Failed to assign permissions');
      error.validationErrors = errors;
      throw error;
    }
  }
);

export const fetchRoleUsers = createAsyncThunk(
  'roles/fetchRoleUsers',
  async (roleId) => {
    try {
      const response = await api.get(`/api/roles/${roleId}/users`);      
      return response.data.users;
    } catch (error) {
      const errors = handleApiError(error.error, 'Failed to fetch role users');
      error.validationErrors = errors;
      throw error;
    }
  }
);

export const fetchRoleApplications = createAsyncThunk(
  'roles/fetchRoleApplications',
  async (roleId) => {
    try {
      const response = await api.get(`/api/roles/${roleId}/applications`);      
      return response.data.applications;
    } catch (error) {
      const errors = handleApiError(error.error, 'Failed to fetch role applications');
      error.validationErrors = errors;
      throw error;
    }
  }
);

export const assignRoleUsers = createAsyncThunk(
  'roles/assignRoleUsers',
  async ({ roleId, newUsers, usersToRemove }) => {
    try {
      const response = await api.post(`/api/roles/${roleId}/users`, {
        newUsers,
        usersToRemove
      });
      return response.data;
    } catch (error) {
      const errors = handleApiError(error.error, 'Failed to assign users to role');
      error.validationErrors = errors;
      throw error;
    }
  }
);

export const assignRoleApplications = createAsyncThunk(
  'roles/assignRoleApplications',
  async ({ roleId, newApplications, applicationsToRemove }) => {
    try {
      const response = await api.post(`/api/roles/${roleId}/applications`, {
        newApplications,
        applicationsToRemove
      });
      return response.data;
    } catch (error) {
      const errors = handleApiError(error.error, 'Failed to assign applications to role');
      error.validationErrors = errors;
      throw error;
    }
  }
);

const initialState = {
  loading: false,
  submitting: false,
  error: null,
  errors: null,
  selectedItem: {},
  openModal: false,
  initialSelectedPermissions: [],
  initialSelectedUsers: [],
  initialSelectedApplications: [],
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    toggleModal: (state) => {
      state.openModal = !state.openModal;
    },
    clearSelectedPermissions: (state) => {
      state.initialSelectedPermissions = [];
    },
    clearSelectedUsers: (state) => {
      state.initialSelectedUsers = [];
    },
    clearSelectedApplications: (state) => {
      state.initialSelectedApplications = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRolePermissions.fulfilled, (state, action) => {        
        state.initialSelectedPermissions = action.payload;        
      })
      .addCase(assignRolePermissions.pending, (state) => {
        state.submitting = true;
      })
      .addCase(assignRolePermissions.fulfilled, (state) => {
        state.submitting = false;
      })
      .addCase(assignRolePermissions.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.error.message;
      })
      .addCase(fetchRoleUsers.fulfilled, (state, action) => {        
        state.initialSelectedUsers = action.payload;        
      })
      .addCase(assignRoleUsers.pending, (state) => {
        state.submitting = true;
      })
      .addCase(assignRoleUsers.fulfilled, (state) => {
        state.submitting = false;
      })
      .addCase(assignRoleUsers.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.error.message;
      })
      .addCase(fetchRoleApplications.fulfilled, (state, action) => {        
        state.initialSelectedApplications = action.payload;        
      })
      .addCase(assignRoleApplications.pending, (state) => {
        state.submitting = true;
      })
      .addCase(assignRoleApplications.fulfilled, (state) => {
        state.submitting = false;
      })
      .addCase(assignRoleApplications.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.error.message;
      })
  },
});

export const { toggleModal, clearSelectedPermissions, clearSelectedUsers, clearSelectedApplications } = rolesSlice.actions;

export const {
    useGetRolesQuery,
    useAddRoleMutation,
    useDeleteRoleMutation,
} = rolesApi; 

export default rolesSlice.reducer;
