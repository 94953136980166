// ** Redux Imports
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { permissionsApi } from './permissions';
import { usersApi } from './users';
import { rolesApi } from './roles';
import { applicationsApi } from './applications';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      permissionsApi.middleware, 
      usersApi.middleware, 
      rolesApi.middleware, 
      applicationsApi.middleware
    );
  },
});

export { store };
