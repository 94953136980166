// ** React Imports
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// ** Redux Imports
import { store } from "./redux/store";
import { Provider } from "react-redux";

// ** ThemeColors Context

import { themeConfig,ThemeContext,Spinner } from "@app/layout";

// ** ThemeConfig

// ** Toast
import { ToastProvider } from '@src/components/shared/ToastProvider';

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";
// // ** Core styles

//core
import "primereact/resources/primereact.min.css"; 

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

import React from 'react'

function RootApp() {
  return (
    <BrowserRouter >
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <ThemeContext>
            <ToastProvider />
            <LazyApp />
            {/* <Toaster
              position={themeConfig.layout.toastPosition}
              toastOptions={{ className: "react-hot-toast" }}
            /> */}
          </ThemeContext>
        </Suspense>
      </Provider>
    </BrowserRouter>
  )
}

export default RootApp
